<template>
    <button class="btn btn-gray btn-sm mr-1 ml-1" @click="exportFile">
        <i class="fa fa-download icon-left"></i> Xuất dữ liệu
        <b-modal v-model="exporting" title="Đang tải dữ liệu" @close="close" no-close-on-esc no-close-on-backdrop no-enforce-focus>
            <div class="alert alert-warning">
                <b-spinner small variant="primary"></b-spinner>&nbsp;
                <span>Dữ liệu đang được tải xuống! Xin vui lòng đợi trong giây lát và xin đừng tắt cửa sổ này cho đến khi quá trình tải xuống hoàn tất.</span>
                {{downloadedCount}} - {{totalCount}}
                <b-progress :max="totalCount">
                    <b-progress-bar :value="downloadedCount" :label="`${((downloadedCount / totalCount) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
            </div>
        </b-modal>
    </button>
</template>

<script>
    import Export from "@/mixins/Export";
    import moment from 'moment';

    export default {
        props: ['filter', 'totalCount', 'params', 'types', "degreeDisabilityOptions", "victimOptions", "prime", "mineVictimOptions"],
        mixins: [Export],
        data() {
            return {
                service: '/patient-other',
                fileName: `Danh sach nguoi khuyet tat`,
                headers: this.prime ? [
                    {header: 'client_id', key: 'client_id', label: 'Đơn vị', width: 26},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên', width: 30},
                    {header: 'dob', key: 'dob', label: 'Năm sinh', width: 15},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố', width: 25},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện', width: 25},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường', width: 25},
                    {header: 'client', key: 'client', label: 'Đơn vị cung cấp dịch vụ', width: 25},
                    {header: 'start_time', key: 'start_time', label: 'Ngày nhận dịch vụ', width: 25},
                    {header: 'types', key: 'types', label: 'Loại hình dịch vụ được cung cấp', width: 25},
                ] : [
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên', width: 30},
                    {header: 'dob', key: 'dob', label: 'Năm sinh', width: 15},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố', width: 25},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện', width: 25},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường', width: 25},
                    {header: 'client', key: 'client', label: 'Đơn vị cung cấp dịch vụ', width: 25},
                    {header: 'start_time', key: 'start_time', label: 'Ngày nhận dịch vụ', width: 25},
                    {header: 'types', key: 'types', label: 'Loại hình dịch vụ được cung cấp', width: 25},
                ],
            }
        },
        methods: {
            setupSheet: function (sheet) {
                let cols = [];
                let row = {};
                for (let i in this.headers) {
                    let header = this.headers[i];
                    cols.push(header);
                    row[header.key] = header.label;
                }
                sheet.columns = cols;
                sheet.addRow(row);


                let provinces = [];
                var tb = this.params.provinceImport;
                for (const key in tb) {
                    provinces.push(key + ". " + tb[key]);
                }


                row = {};
                if(this.prime){
                    row['client_id'] = "Nhập định dạng chữ";
                }
                row["fullname"] = "Nhập định dạng chữ (tối đa 200 ký tự)";
                row["dob"] = "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)";
                row["province_id"] = "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row["district_id"] = "Nhập định dạng chữ";
                row["ward_id"] = "Nhập định dạng chữ";
                row["client"] = "Nhập định dạng chữ";
                row['start_time'] = "Nhập định dạng năm (yyyy)";
                row['types'] = "Nhập 01 giá trị từ 1 đến 4 (" + this.$params.patientOther.typeOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                sheet.addRow(row);
                row.alignment = {wrapText: true};
                //ẩn dòng 1
                sheet.getRow(1).hidden = true;
                sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'bdbdbd'
                    }
                };

                // đôi đậm và wrap text
                sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(2).font = {
                    bold: true
                };
                //bôi màu
                sheet.getRow(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '92D050'
                    }
                };
                //Dòng 3
                sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(3).font = {
                    italic: true
                };


            },
            addRow(item, params) {
                let row = {
                    client_id: params.clients && params.clients[item.client_id] ? params.clients[item.client_id].short_name : '',
                    fullname: item.fullname,
                    dob: item.dob != 0 ? moment(item.dob * 1000).format('YYYY'): '',
                    province_id: params.provinces && params.provinces[item.province_id] ? params.provinces[item.province_id].name : '',
                    district_id: params.districts && params.districts[item.district_id] ? params.districts[item.district_id].name : '',
                    ward_id: params.wards && params.wards[item.ward_id] ? params.wards[item.ward_id].name : '',
                    client: item.client,
                    start_time: item.start_time != 0 ? moment(item.start_time * 1000).format('YYYY'): '',
                    types: this.getTypes(params.tys[item.id]),
                };
                if(this.prime){
                    row['client_id'] = params.clients[item.client_id].short_name;
                }
                return row;
            },
            getTypes(data) {
                let text = "";
                if (data) {
                    for (let item of data) {
                        if(item != -1){
                            text = text + this.$params.patientOther.typeOptions.find(o => o.value == item).text + "; ";
                        }
                    }
                }
                return text.substring(0, text.length - 2);
            },
        }
    }
</script>