<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :placeholder="'Nhập mã, họ tên ...'" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Danh sách người khuyết tật (dự án khác)" titleIcon="fa fa-user-injured">
                <template slot="filter">
                    <client-select v-if='isPrime() || isTW() ||  isUsaid()' attribute="client_id"  :model="filterFormModel" :errors="formErrors"  :filter="{parent_id: $store.state.client.id}" :is-filter='true' :placeHolder="isPrime() ? 'Chọn đơn vị thầu phụ' : 'Chọn đơn vị thầu chính'" class="mr-2" @change='searchClient' />
                    <client-select v-if='(isTW() ||  isUsaid()) && filterFormModel.client_id' attribute="client_sub_id"  :model="filterFormModel" :errors="formErrors" placeHolder="Chọn đơn vị thầu phụ"  :filter="{parent_id: filterFormModel.client_id}" :is-filter='true' class="mr-2" @change='search' />
                    <province-select :model="filterFormModel" attribute="province_id"  :is-filter='true'  class="mr-2"  @change='search' />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="types"  :options="$params.patientOther.typeOptions" :isFilter="true" @change="search" />
                </template>
                <template #cell(client_id)="{item}">
                    <span v-if='params.clients[item.client_id]' >{{params.clients[item.client_id].short_name}}</span>
                </template>
                <template #cell(fullname)="{item}">
                    {{item.fullname}}
                </template>
                <template #cell(info)="{item}">
                    <div v-if="item.dob"><span class="small text-gray-999">Năm sinh: </span>{{item.dob | moment('YYYY')}}</div>
                    <div v-if="params.wards[item.ward_id] || params.districts[item.district_id] || params.provinces[item.province_id]"><span class="small text-gray-999">Nơi sinh sống: </span>
                        <span v-if='params.wards[item.ward_id]' >{{params.wards[item.ward_id].name}}, </span>
                        <span v-if='params.districts[item.district_id]' >{{params.districts[item.district_id].name}}, </span>
                        <span v-if='params.provinces[item.province_id]' >{{params.provinces[item.province_id].name}}</span>
                    </div>
                </template>
                <template #cell(types)="{item}">
                    <div v-if="item.client"><span class="small text-gray-999">Đơn vị cung cấp dịch vụ: </span>{{item.client }}</div>
                    <div v-if="item.start_time"><span class="small text-gray-999">Năm nhận dịch vụ: </span>{{item.start_time | moment('YYYY')}}</div>
                    <div v-if="params.tys[item.id]" ><span class="small text-gray-999">Loại hình dịch vụ được cung cấp: </span> 
                        <span v-for="tys_id in params.tys[item.id]" :key="tys_id" >
                            <span class="badge bg-gray ml-1" v-if='$params.patientOther.typeOptions.find(o => o.value === tys_id)'>{{ $params.patientOther.typeOptions.find(o => o.value === tys_id).text}}</span>
                        </span>
                    </div>
                </template>
                <template slot="action">
                    <export v-if="permissions('patient-other_export')" :filter="filterFormModel" :params='params' :prime="isPrime() || isTW() || isUsaid()" :totalCount="itemCount" class="mr-1"/>
                    <g-button class="ml-1 btn btn-gray btn-sm" icon="fa fa-file-excel" :loading="saveLoading" size="sm" variant="success" content="Tải xuống tập tin mẫu" @click="downloadTemplate()" />
                    <import  v-if="permissions('patient-other_import')" :client_id="params.clientId" :params='params' class="mr-1"/>
                </template>
                <template #cell(action)="{item}">
                    <g-button  v-if="permissions('patient-other_view')" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                    <g-button  v-if="permissions('patient-other_del')" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    <log type="patient-other" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showFormView" size='lg' :title="formTitle"   cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Họ và tên" disabled :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Đơn vị cung cấp dịch vụ" disabled :model="formModel" attribute="client"  :errors="formErrors" ></form-input>
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-date-time   disabled label="Năm sinh" :model="formModel" :errors="formErrors" attribute="dob" format="YYYY" placeholder="YYYY" />
                </div>
                <div class='col-md'>
                    <form-date-time   disabled label="Năm nhận cung cấp dịch vụ" :model="formModel" :errors="formErrors" attribute="start_time" format="YYYY" placeholder="YYYY" />
                </div>
                <div class='col-md'>
                    <form-select-multiple :required='true' disabled  :close-on-select='false' label="Loại hình dịch vụ được cung cấp" :model="formModel" attribute="types" :errors="formErrors" :options="patientOtherTypeOptions" />
                </div>
            </div>
            <form-group-address :provinceDisabled="true" :districtDisabled="true" :wardDisabled="true" class="pr-1" :model="formModel" :errors="formErrors"></form-group-address>
            <template v-slot:modal-footer="{ cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import Dropdown from '@/components/Dropdown';
    import ClientSelect from '@/components/ClientSelect';
    import ProvinceSelect from '@/components/ProvinceSelect';
    import Log from "@/components/Log";
    import Export from './PatientOtherExport';
    import Import from './PartientOtherImport';
    import ImportMinxi from "@/mixins/Template";

    export default {
        components: {ClientSelect, ProvinceSelect, Log, Dropdown, Export, Import},
        mixins: [list, crud, role, ImportMinxi],
        data: function () {
            return {
                reloadBus: "reloadPatientOther",
                service: '/patient-other',
                defaultFilterFormModel: {
                    parameters: [
                        this.$params.const.race.key,
                    ],
                    keyword: '',
                    client_id: this.isPrime() || this.isTW() || this.isUsaid() ? '' : this.$store.state.client.id,
                    types: null,
                    client_sub_id: null,
                },
                defaultFormModel: {
                    types: [],
                    agent_orange_victim: null,
                    degree_disability: null,
                    province_id: null,
                    district_id: null,
                    ward_id: null,
                },
                patientOtherTypeOptions: [],
                showFormView: false,
                fileName: `patient-other`,
                templateName: 'NKT_nhan_ho_tro_tu_du_an_khac',
                headers: [
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên*', width: 30, desc: "Nhập định dạng chữ (tối đa 200 ký tự)"},
                    {header: 'dob', key: 'dob', label: 'Năm sinh', width: 15, desc: "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố', width: 25, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện', width: 25, desc: "Nhập định dạng chữ "},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'client', key: 'client', label: 'Đơn vị cung cấp dịch vụ', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'start_time', key: 'start_time', label: 'Ngày nhận dịch vụ', width: 25, desc: "Nhập định dạng năm(yyyy)"},
                    {header: 'types', key: 'types', label: 'Loại hình dịch vụ được cung cấp', width: 25, desc: "Nhập 01 giá trị từ 1 đến 4 (" + this.$params.patientOther.typeOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                ],
                indexRow: 4,

            };
        },
        computed: {
            fields() {
                let fields = [
                    {key: 'fullname', label: "Người khuyết tật"},
                    {key: 'info', label: "Thông tin NKT"},
                    {key: 'types', label: "Thông tin khác"},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ];
                if (this.isPrime() || this.isTW() || this.isUsaid()) {
                    return [{key: 'client_id', label: "Đơn vị"}].concat(fields);
                }
                return fields;
            },
        },
        watch: {
            "formModel.code": {
                handler(val) {
                    if (val) {
                        this.formModel.code = val.toUpperCase().trim();
                    }
                }, deep: true
            }
        },
        methods: {
            searchClient() {
                this.filterFormModel.client_sub_id = null;
                this.filterFormModel.page = 1;
                this.load();
            },
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    if (this.beforeUpdate) {
                        this.beforeUpdate(response.data);
                    }
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin NKT: " + this.formModel.fullname;
                    this.formModel.id = id;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
            beforeUpdate(data) {
                data.dob = data.dob ? data.dob * 1000 : null;
            },
            getPatientTypeOption(){
                for (let i in this.$params.patientOther.typeOptions){
                    let id = this.$params.patientOther.typeOptions[i];
                    if(id.value != null){
                        this.patientOtherTypeOptions.push(id);
                    }  
                }
                return this.patientOtherTypeOptions;
            },
        },
        mounted(){
            this.getPatientTypeOption();
        },
    }
</script>